<template>
  <div :data-test-id="elementTest">
    <div class="input-phone-label" :data-test-id="elementTest + '-label'">
      <p
        v-if="phoneLabel && (rawInputValue !== '' || fieldFocus)"
        :class="{ 'u-c-primary': greenTitleWhenCorrect && correctInputValue }"
        :data-test-id="elementTest + '-text'"
      >
        {{ $t('fields.phone') }}
      </p>
    </div>
    <div
      class="input-phone"
      :class="[classVariant, { 'u-m-b-less': reinsuranceMessage }, { reinsurance: reinsuranceMessage }]"
    >
      <ClientOnly>
        <SelectLocation v-model="location" :select-country="selectCountry" :element-test="elementTest + '-select'" />
      </ClientOnly>
      <div class="input-phone-tel">
        <div v-if="location" class="input-phone-tel-dialingcode" :data-test-id="elementTest + '-dialingcode'">
          <p>{{ location.dialingCode }}</p>
        </div>
        <InputField
          v-model="phone"
          input-id="phone"
          input-type="tel"
          :placeholder-value="$t('fields.phone')"
          :class-variant="classVariant || 'bg-grey'"
          :error="error"
          :required="required"
          :input-rules="required ? ['required', 'numeric'] : ['numeric']"
          :input-formatter="phoneFormatter"
          :reinsurance-message="reinsuranceMessage"
          :element-test="elementTest + '-input'"
          @focus="fieldFocus = true"
          @blur="e => manageBlur(e)"
          @change="e => $emit('change', e)"
          @input="captureRawInput"
        />
      </div>
    </div>
    <div
      v-if="reinsuranceMessage"
      class="reinsurance-message"
      :class="{
        'u-p-t-md': rawInputValue !== '' && error !== '',
      }"
    >
      {{ reinsuranceMessage }}
    </div>
  </div>
</template>

<script>
import i18nMx from '@/mixins/i18n-mixin'
import { nextTick } from 'vue'

import { isValidPhoneNumber, Metadata } from 'libphonenumber-js'

import SelectLocation from '@/components/SelectField/SelectLocation'
import InputField from '@/components/InputField/InputField'

export default {
  name: 'InputPhone',
  components: {
    InputField,
    SelectLocation,
  },
  mixins: [i18nMx],
  props: {
    modelValue: { type: Object, default: () => {} },
    required: { type: Boolean, default: false },
    classVariant: { type: String, default: null },
    phoneLabel: { type: Boolean, default: true },
    selectCountry: { type: Boolean, default: false },
    reinsuranceMessage: { type: String, default: null },
    greenTitleWhenCorrect: { type: Boolean, default: false }, // Green title label when input value is ok
    elementTest: { type: String, default: '' },
  },
  emits: ['update:modelValue', 'is-phone-invalid', 'change', 'update:model-value', 'is-phone-invalid'],
  data() {
    return {
      location: null,
      error: '',
      fieldFocus: false,
      rawInputValue: '',
    }
  },
  computed: {
    phone: {
      get() {
        return this.modelValue?.phone_local || ''
      },
      set(v) {
        this.setInput(v)
      },
    },
    correctInputValue() {
      return this.phone && this.phone !== '' && this.error === ''
    },
  },
  mounted() {
    const { tld } = useCurrentLocale()
    this.location = this.countries?.find(c => c.code?.toUpperCase() === tld)
    const metadata = new Metadata()
    metadata.selectNumberingPlan(tld)
    this.location.phoneMaxLength = (Math.max(...(metadata?.numberingPlan?.possibleLengths() || [])) + 1).toString()
    if (this.required) {
      this.$emit('is-phone-invalid', true)
    }
    nextTick(() => {
      if (this.modelValue) {
        if (this.modelValue?.phone_number?.includes(this.location.dialingCode)) {
          const phone = this.modelValue.phone_number.replace(this.location.dialingCode, '0')
          this.setInput(phone)
        } else if (this.modelValue?.phone_number) {
          this.setInput(this.modelValue.phone_number)
        } else {
          this.setInput(this.modelValue)
        }
      }
    })
  },
  methods: {
    manageBlur() {
      this.fieldFocus = false
    },
    setInput(e) {
      const { tld } = useCurrentLocale()
      if (!e) {
        this.error = this.$t('rules.required')
        this.$emit('is-phone-invalid', true)
      }
      if (e && !isValidPhoneNumber(e, tld)) {
        this.error = this.$t('subscribe.errors.phoneNumber')
        this.$emit('is-phone-invalid', true)
      } else {
        this.error = ''
        let phoneInput = String(e)
        if (phoneInput[0] === '0') {
          phoneInput = phoneInput.substring(1, phoneInput.length)
        }
        const phone = {
          phone_number: this.location?.dialingCode + phoneInput,
          phone_prefix: this.location?.dialingCode.substring(1),
          phone_local: e,
        }
        this.$emit('is-phone-invalid', false)
        this.$emit('update:modelValue', phone)
      }
    },
    phoneFormatter(e) {
      if (e) {
        return e.replaceAll(/\s+/g, '').substring(0, this.location?.phoneMaxLength)
      }
      return e
    },
    captureRawInput(event) {
      this.rawInputValue = event.target.value
    },
  },
}
</script>

<style lang="scss">
.input-phone-label {
  display: flex;
  align-items: center;
  height: 28px;
  p {
    font-size: pxToRem(12px);
    color: var(--steel-grey);
    font-weight: 500;
    padding-left: $spacing-sm;
  }
}

.input-phone {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing-sm;
  background-color: var(--bg-grey);
  padding: $spacing-xxxs;
  height: 40px;
  margin-bottom: $spacing-xxxl;

  .sp-input-field .error,
  .reinsurance-message {
    float: inherit;
    margin-bottom: 0;
    left: 0;
  }

  .sp-input-error {
    position: absolute;
  }

  .sp-input-field {
    margin-bottom: 0;
  }

  &-tel {
    display: flex;
    align-items: center;

    &-dialingcode {
      p {
        color: var(--steel-grey) !important;
        font-size: pxToRem(12px);
        font-weight: 500;
        line-height: 1;
      }
    }

    .sp-input {
      padding-left: $spacing-xs;
      padding-bottom: $spacing-xxxs;
    }
  }
}

.reinsurance-message {
  color: var(--steel-grey);
  font-size: pxToRem(13px);
  padding: 3px $spacing-sm 0 $spacing-sm;
}
</style>
